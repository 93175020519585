import React from 'react';

const ProductDetailMobile = ({
  product,
  processedImage,
  priceFontSizeMobile,
  nameFontSizeMobile,
  nameColor,
  textEffect,
  useEffectiveText,
  titleFontFamily,
  customPrice,
  priceColor,
}) => {
  
  const priceInDollars = (customPrice || product.price) / 100;
  const formattedPrice = priceInDollars.toFixed(2);
  return (
    <div className="product-details-NFT">
      {processedImage && (
        <img
          src={processedImage}
          alt={product.name}
          className="NFT-transparent-image"
        />
      )}
      {product.name && (
        <div
        className={` NFT-Name ${
          useEffectiveText ? textEffect : ""
        }`}
          style={{
            fontSize: nameFontSizeMobile,
            color: nameColor,
            fontFamily: titleFontFamily,
          }}
        >
          {product.name}
        </div>
      )}
      {(customPrice || product.price) && (
        <div
        className={` NFT-Price ${
          useEffectiveText ? textEffect : ""
        }`}
          style={{
            fontSize: priceFontSizeMobile,
            color: priceColor,
            fontFamily: titleFontFamily,
          }}
        >
          ${customPrice || formattedPrice}
        </div>
      )}
    </div>
  );
};

export default ProductDetailMobile;

// src/redux/fontSlice.js
import { createSlice } from "@reduxjs/toolkit";

const fontSlice = createSlice({
  name: "fonts",
  initialState: {
    titleFontSize: "16px", // Default size
    priceFontSize: "16px", // Default size
    nameFontSize: "16px",  // Default size
  },
  reducers: {
    setTitleFontSize: (state, action) => {
      state.titleFontSize = action.payload;
    },
    setPriceFontSize: (state, action) => {
      state.priceFontSize = action.payload;
    },
    setNameFontSize: (state, action) => {
      state.nameFontSize = action.payload;
    },
  },
});

// Export actions
export const { setTitleFontSize, setPriceFontSize, setNameFontSize } = fontSlice.actions;

// Export reducer
export default fontSlice.reducer;

// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import fontReducer from "./fontSlice";
import rightSlice from "./rightSlice";
import accountSlice from "./accountSlice";
import tabSlice from "./tabSlice";

const store = configureStore({
  reducer: {
    fonts: fontReducer, 
    rights: rightSlice,
    account: accountSlice,
    tab: tabSlice,
  },
});

export default store;

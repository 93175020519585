import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Table } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import { Form } from "react-router-dom";

const DiscountId = () => {
  const [activities, setActivities] = useState([]);


  return (
    <>
    <Sidebar  />
    <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Discount Id</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrumb */}
            </div>
            <div className="full-div">
              <h1>
              Discount Id{" "}
                <span
                  className="view-title"
                ></span>
              </h1>
            </div>
          </div>

          <div className="white-div">
            <div className="table-container">
              <div className="table-container-inner">
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Password</th>
                      <th>Permission</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {/* <tbody>
                    {activities?.length > 0 ? (
                      activities?.map((activity, index) => (
                        <tr key={index}>
                          <td>{activity.name}</td>
                          <td>{activity.email}</td>
                          <td>{activity.password}</td>
                          <td>{activity.permission}</td>
                          <td>{activity.action}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No Redemption Activities Found
                        </td>
                      </tr>
                    )}
                  </tbody> */}
                </Table>
              </div>
            </div>

            <div className="full-div text-center pagination-container">
              {/* Pagination Logic (Optional, depending on your needs) */}
            </div>
          </div>
        </div>
    </main>
    </>
  );
};

export default DiscountId;

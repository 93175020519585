import React from "react";
import { Link } from "react-router-dom";
import { Form, Breadcrumb, Table, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { handleGetAllCoupons, handleGetUserList } from "../shared/api";
import { useState, useEffect } from "react";
import { Pagination } from "react-pagination-bar";
import CouponScreen from "../Components/CouponScreen";
import CustomLoader from "../Components/CustomeLoader";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import { Searchbar } from "../Components/Searchbar";
import OGBXLoader from "../../src/assets/images/Loader.gif"
import LocationModal from "./LocationModal";
import axios from "axios";
import { Node_BE_URL } from "../constant";
function CouponManagment() {
  const { rightAccess } = useSelector(rightSelector);
  const [loader, setloader] = useState(true);
  const [coupnsData, setcoupnsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchedString, setsearchedString] = useState('');
  const [checked, setchecked] = useState(null);  
  const [locations, setLocations] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const CHECKS = {
    TierBased: 1,
    Productbased: 2,
    Marketbased: 3,
    IsPromotion :4

  }
  useEffect(() => {
    getCouponsData(currentPage, searchedString)
  }, [currentPage, checked,selectedLocation]);

  useEffect(() => {
    // getCouponsData(1, searchedString)
    // setCurrentPage(1)
  }, []);


  const getCouponsData = (pageNumber = 1, search = '') => {
    setloader(true)
    handleGetAllCoupons(pageNumber, search, checked == CHECKS.TierBased, checked == CHECKS.Productbased, checked == CHECKS.Marketbased  , checked === CHECKS.IsPromotion,selectedLocation)
      .then((res) => {
        if (currentPage !== pageNumber) setCurrentPage(pageNumber)
          console.log("first page",res?.data)
        setcoupnsData(res?.data);
        setTotalItems(res.totalItems);
        setloader(false);

      })
      .catch((err) => { setloader(false) });
  }

  useEffect(() => {
    if (searchedString.length == 0) {
      getCouponsData()
    }
  }, [searchedString]);

  const getAllCoupons = () => {
    setloader(true)
    // setCurrentPage(1)
    handleGetAllCoupons(currentPage, '', false, false, false)
      .then((res) => {
        setloader(false)
        setcoupnsData(res.data.data);
        setTotalItems(res.data.totalItems);
      })
      .catch((err) => { setloader(false) });
  }
  const handleSearch = () => {
    setloader(true);
    // setCurrentPage(1)
    handleGetAllCoupons(1, searchedString, checked == CHECKS.TierBased, checked == CHECKS.Productbased, checked == CHECKS.Marketbased)
      .then((res) => {
        setloader(false);
        setcoupnsData(res.data.data);
        setTotalItems(res.data.totalItems);
      })
      .catch((err) => { setloader(false); });
  };
  const dataAfterDelete = (id) => {
    setcoupnsData((prev) => [...coupnsData?.filter((x) => x.id !== id)]);
    setTotalItems(totalItems - 1)
  };
  useEffect(() => {
    GetAllLocations(); // Fetch locations when component mounts
  }, []);
/**
 * Fetches all location data from the API and updates the state with the response.
 * The `useEffect` hook calls this function when the component mounts to load locations initially.
 */
  const GetAllLocations = () => {
    axios.get(`${Node_BE_URL}/api/admin/getAllLocations`)
      .then((data) => {
        setLocations(data.data);
      })
      .catch((err) => { });
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location); // Update the selected location
    setModalShow(false); // Close the modal
  };

  return (
    <>
      <Sidebar />


      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">NFT Coupon Management</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <div className="flex-div-sm">
                <h1>NFT Coupon Management</h1>
                <ul className="btn-lister">
                  <li>
                    <div
                      className="reg-btn mr-2 mb-2"
                      onClick={() => {
                        setchecked(CHECKS.Marketbased)
                        setCurrentPage(1)
                        // handleFilter('Approved')
                      }}
                      style={{
                        background: checked == CHECKS.Marketbased ? "#9e75ff" : "white",
                        color: checked == CHECKS.Marketbased ? "white" : "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"
                      }}
                    >Market-Place based</div>
                     <div
                      className="reg-btn mr-2 mb-2"
                      onClick={() => {
                        setchecked(CHECKS.IsPromotion)
                        setCurrentPage(1)
                        // handleFilter('Approved')
                      }}
                      style={{
                        background: checked == CHECKS.IsPromotion ? "#9e75ff" : "white",
                        color: checked == CHECKS.IsPromotion ? "white" : "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"
                      }}
                    >Promotional based</div>
                    <div
                      className="reg-btn mr-2 mb-2"
                      onClick={() => {
                        setchecked(CHECKS.TierBased)
                        setCurrentPage(1)
                        // handleFilter('Approved')
                      }}
                      style={{
                        background: checked == CHECKS.TierBased ? "#9e75ff" : "white",
                        color: checked == CHECKS.TierBased ? "white" : "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"
                      }}


                    >Tier based</div>
                    <div
                      onClick={() => {
                        setchecked(CHECKS.Productbased)
                        setCurrentPage(1)
                        // handleFilter('Unapproved')

                      }}
                      style={{
                        background: checked == CHECKS.Productbased ? "#9e75ff" : "white",
                        color: checked == CHECKS.Productbased ? "white" : "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"

                      }}
                      className="reg-btn mr-2 mb-2"

                    >Product based</div>
                    <div
                      onClick={() => {
                        setchecked(null)
                        getAllCoupons()
                        setCurrentPage(1)
                        setsearchedString('')

                      }}
                      style={{
                        background: "white",
                        color: "#9e75ff",
                        border: "1px solid #9e75ff",
                        cursor: "pointer"

                      }}
                      className="reg-btn mr-2 mb-2"

                    >Reset </div>
                    <div
                    className="reg-btn mr-2 mb-2 reset-container"
 onClick={() => setModalShow(true)} >
        Select Store 

      </div>
      <LocationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        locations={locations}
        onSelect={handleLocationSelect}
        initialLocation={selectedLocation}  // Pass selectedLocation as initialLocation to modal

      />

      {selectedLocation && (
        <div>
          <p>Selected Location Name: {selectedLocation.name}</p>
        </div>
      )}


                  </li>
                  <li>

                    <div className="search-pnl">

                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Searchbar setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} />

                      </Form>
                    </div>
                  </li>
                  {/* {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() ===
                      "coupon management" && right.isAdd
                  ) && (
                      <li>
                        <Link to="/AddNewCoupon" className="reg-btn">
                          <i className="fa fa-plus"></i> Add New Coupon
                        </Link>
                      </li>
                    )} */}

                </ul>
              </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        {/* <th>Associated NFTs</th> */}
                        <th>Expiration Dates</th>
                        <th>Redemption Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <CustomLoader isLoading={loader} /> */}
                      {!loader ? coupnsData?.map((couponData) => (
                        <CouponScreen
                          couponData={couponData}
                          dataAfterDelete={dataAfterDelete}
                        />
                      )) : <CustomLoader isLoading={loader} />}

                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="full-div">
                {!loader && <h3
                  style={{
                    color: "#AD79F7",
                    fontSize: "16px",
                    fontWeight: "700",
                    paddingLeft: "15px",
                  }}
                >
                  {totalItems} Coupons
                </h3>}
              </div>
              <div className="full-div text-center pagination-container">
                {!loader && <Pagination
                  totalItems={totalItems}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />}
                {/* <Link to="/" className="reg-btn big">
                  View More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default CouponManagment;

import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Table } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import { Form } from "react-router-dom";
import { Searchbar } from "../Components/Searchbar";
import { Pagination } from "react-pagination-bar";

import { handleGetRedeemActivity, handleGetUserList } from "../shared/api";
import moment from "moment";
import { API_URL } from "../constant";

const RedemptionActivity = () => {
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loader, setloader] = useState(true);

  useEffect(() => {
    getUserList();
  }, [currentPage]);

  const getUserList = () => {
    setloader(true);
    handleGetRedeemActivity(currentPage)
      .then((res) => {
        setActivities(res?.data?.redeemedActions);
        setTotalItems(res?.data?.pagination?.totalRedeemedActions);
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Redemption Activity</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrumb */}
            </div>
            <div className="full-div">
              <h1>
                Redemption Activity <span className="view-title"></span>
              </h1>
              <ul className="btn-lister">
                <li>
                  <div className="search-pnl">
                    {/* <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Searchbar
                         setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} 
                         />
                      </Form> */}
                  </div>
                </li>
              </ul>
            </div>

            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>NFT Name</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Redeem Date</th>
                        <th>Image</th>
                      </tr>
                    </thead>

                    {!loader ? (
                      activities?.length > 0 ? (
                        <tbody>
                          {activities.map((activity) => (
                            <tr key={activity._id}>
                              <td>{activity.Details.NftName}</td>
                              <td>{activity.Details.ProductName}</td>
                              <td>{activity.Details.Price}</td>

                              <td>
                                {moment(activity.Details.RedeemDate).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>
                              
                              <td>
                                <img
                                  src={`${API_URL}${activity.Details.Image}`}
                                  alt={activity.Details.NftName}
                                  width="50"
                                  height="50"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <tr>
                            <td colSpan="5">No Record Found</td>
                          </tr>
                        </tbody>
                      )
                    ) : (
                      <CustomLoader isLoading={loader} />
                    )}
                  </Table>
                </div>
              </div>

              <div className="full-div text-center pagination-container">
                <Pagination
                  totalItems={totalItems}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RedemptionActivity;

import axios from "axios";

// export const API_URL = 'https://onecmadmin.ibt-learning.com/'
export const API_URL = 'https://onecm-apis.onecannabismarketinc.com/'
// export const API_URL = 'https://stagging-1cm-admin-api.ammag.tech/'
// export const API_URL = 'https://onecm.ammag.tech/'
export const WareHouse_API_URL = 'https://onecm-warehouse.onecannabismarketinc.com/api/middleware/'
export const Node_BE_URL = 'https://onecmapp-apis.onecannabismarketinc.com'
// export const Node_BE_URL = 'https://onecm-loyalty.ammag.tech'

// export const Node_BE_URL = 'http://192.168.1.20:8080'
export const ScreeNname  = {
  nftCollection :'nft collection management'
}


// export const API_URL = 'https://onecmadmin.ibt-learning.com/'
export const MAX_FILE_SIZE_BYTES =52428800

export const http = {
  setAuthorizationHeader: () => {
    const accessToken = localStorage.getItem("Token");
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    axios.defaults.headers.Authorization = "Bearer " + accessToken;
  },
  request(config = {}) {
    return axios.request(config);
  },
  get(url, config = {}) {
    return axios.get(url, config);
  },
  post(url, data = {}, config = {}) {
    return axios.post(url, data, config);
  },
  put(url, data = {}, config = {}) {
    return axios.put(url, data, config);
  },
  patch(url, data = {}, config = {}) {
    return axios.patch(url, data, config);
  },
  delete(url, config = {}) {
    return axios.delete(url, config);
  },
  pageSize: 999999,
};


export const colors={
mainColor:'#ad79f7'
}
 export const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: '850px',
  }),
  control: (provided) => ({
    ...provided,
    width: '850px',
  }),
};

 export const previewSizes = {
  Greenline_Mobile: { width: "100%", height: "510px" },
  Greenline_Desktop: { width: "100%", height: "280px" },
  Menu_Board_TV_Landscape: { width: "100%", height: "680px" },
  PORTRAIT_SIZE: { width: "100%", height: "1320px" },
  Mobile_NFT:{width:'400px',height:'400px'},
  A4_Size:{width:'793.7px',height:' 1122.52px'},
  A4Size:{width:'793.7px',height:' 1122.52px'}

};

export const downloadSizes = {
  Greenline_Mobile: { width: "1800px", height: "810px" },
  Greenline_Desktop: { width: "2680px", height: "360px" },
  Menu_Board_TV_Landscape: { width: "1920px", height: "1080px" },
  PORTRAIT_SIZE: { width: "1080px", height: "1920px" },
  Mobile_NFT:{width:'400px',height:'400px'},
  A4_Size:{width:'793.7px',height:' 1122.52px'},
  A4Size:{width:'793.7px',height:' 1122.52px'}


};


export const API_URLs =
  "https://onecm-loyalty.ammag.tech/api/getProducts";


export const sizes = [
    { name: 'GreenlineMobile', dimensions: { width: '1800px', height: '810px' } },
    { name: 'GreenlineDesktop', dimensions: { width: '2680px', height: '360px' } },
    { name: 'MenuBoardTVLandscape', dimensions: { width: '1920px', height: '1080px' } },
    { name: 'MenuBoardTVPortrait', dimensions: { width: '1080px', height: '1920px' } },
  ];
  export const placeholderurlmobile='https://placehold.co/1800x810'
  export const placeholderurldesktop='https://placehold.co/2680x360'
  export const placeholderurllandscape='https://placehold.co/1920x1080'
  export const placeholderurlpotrait='https://placehold.co/1080x1920'
  export const placeholderurlMobileNFT='https://placehold.co/400x400'
   export const A4_Size='https://placehold.co/793x1122'


  export const REMOVE_BG = 'https://onecm-banner.ammag.tech/remove-background';

  export const currentFontSizes = [
    'Select Size','8px','10px','12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px','38px','40px','50px','60px','70px','80px'
  ];
  export const fontSizes = [
    'Select Size','8px','10px','12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px','38px','40px','50px','60px','70px','80px'
  ];
  
  export const fontFamilies = [
   'Lovelo Black',
   'Arial',
   'Verdana',
   'Times New Roman',
   'Helvetica',
   'Tahoma', 
   'Trebuchet MS',
   'Garamond',
   'Arial Black',
   'Century Gothic  ',
   
  ];
  export const INITIAL_name_Size_NFT = "32px";
  export const INITIAL_Font_Size_name = "14px";
  export const INITIAL_TITLE_COLOR = "#c8b5f6";
  export const INITIAL_TITLE_FONT_FAMILY = "Lovelo Black";
  export const INITIAL_titleFontSizeMobile = "30px";
  export const INITIAL_nameFontSizeMobile = "32px";
  export const INITIAL_priceFontSizeMobile = "30px";
  export const INITIAL_TITLE_COLOR_PDF = "#a9d18e";
  export const Star_Color = "#ffffff";


 

  export const modalStyles = {
    Greenline_Mobile: {
      width: "1800px",
      height: "810px",
      productDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        fontSize: "14px",
      },
    },
    Greenline_Desktop: {
      width: "2680px",
      height: "360px",
      productDetails: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        gap: "20px",
        fontSize: "18px",
      },
    },
    Menu_Board_TV_Landscape: {
      width: "1920px",
      height: "1080px",
      productDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "15px",
        fontSize: "16px",
      },
    },
    PORTRAIT_SIZE: {
      width: "1080px",
      height: "1920px",
      productDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        fontSize: "20px",
      },
    },
  };

  export const SHOW_MODAL = "showModal";
export const HANDLE_CLOSE_MODAL = "handleCloseModal";
export const BACKGROUND = "background";
export const TITLE = "title";
export const TITLE_COLOR = "titleColor";
export const TITLE_FONT_SIZE = "titleFontSize";
export const PRICE_FONT_SIZE = "priceFontSize";
export const TITLE_FONT_SIZE_MOBILE = "titleFontSizeMobile";
export const PRICE_FONT_SIZE_MOBILE = "priceFontSizeMobile";
export const NAME_FONT_SIZE_MOBILE = "nameFontSizeMobile";
export const FOOTER = "footer";
export const PROCESSED_IMAGE = "processedImage";
export const SHOW_FOOTER_INPUT = "showFooterInput";
export const SHOW_TC = "showTC";
export const NAME_FONT_SIZE = "nameFontSize";
export const PRODUCT_DETAILS = "productDetails";
export const TITLE_FONT_FAMILY = "titleFontFamily";
export const CUSTOM_PRICE = "customPrice";
export const NAME_COLOR = "nameColor";
export const PRICE_COLOR = "priceColor";
export const BANNER_TYPE = "currentBannerType";


export const TabKeys = {
  HOME: "home",
  GREENLINE_MOBILE: "GreenLine Mobile",
  GREENLINE_DESKTOP: "Greenline Desktop",
  MENU_BOARD_TV: "Menu Board TV",
  PORTRAIT_SIZE: "Potrait Size",
  NFT_SIZE: "NFT Size",
  DOWNLOAD_BANNERS: "Download Banners",
};

export const TabKey = Object.freeze({
  GREENLINE_MOBILE: "GreenLine Mobile",
  GREENLINE_DESKTOP: "GreenLine Desktop",
  MENU_BOARD_TV_LANDSCAPE: "Menu Board TV Landscape",
  PORTRAIT_SIZE: "Portrait Size",
  NFT_SIZE: "NFT Size",
});

export const CrossIcon=() =>{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}
export const gatherTabData = () => {
  const allTabData = [];

  // Collect data from GreenLine Mobile tab
  const mobileTabElement = document.querySelector('.greenline-mobile');
  if (mobileTabElement) {
      const mobileData = {
          type: 'GreenLine Mobile',
          title: mobileTabElement.querySelector('.live-title-mobile')?.innerText || '',
          products: []
      };

      const productElements = mobileTabElement.querySelectorAll('.product-details-mobile');
      productElements.forEach((product) => {
          const name = product.querySelector('.product-name')?.innerText || '';
          const price = product.querySelector('.Desktop-priceTag')?.innerText || '';
          mobileData.products.push({ name, price });
      });

      allTabData.push(mobileData);
  }

  // Collect data from Greenline Desktop tab
  const desktopTabElement = document.querySelector('.greenline-desktop');
  if (desktopTabElement) {
      const desktopData = {
          type: 'Greenline Desktop',
          title: desktopTabElement.querySelector('.live-title')?.innerText || '',
          products: []
      };

      const productElements = desktopTabElement.querySelectorAll('.Desktop-imgcont');
      productElements.forEach((product) => {
          const name = product.querySelector('.Desktop-nameTag')?.innerText || '';
          const price = product.querySelector('.Desktop-priceTag')?.innerText || '';
          desktopData.products.push({ name, price });
      });

      allTabData.push(desktopData);
  }

  return allTabData;
};
